<script>
import Layout from "./horizontal";
import { mapGetters } from "vuex";

export default {
    components: {
        Layout,
    },

    computed: {
        ...mapGetters({
            user: 'auth/currentUser',
            balance: 'credit/balance'
        }),
        messageLink: function () {
            return this.$t('routes.messages').substr(0, this.$t('routes.messages').indexOf(':'));
        },
    },

    data() {
        return {
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                roundingMode: 'floor',
                maximumFractionDigits: 0
            })
        };
    }
};
</script>

<template>
    <Layout>
        <div class="mx-n4 mt-n4">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card mb-0">
                        <div class="card-body p-0">
                            <div class="profile-bg position-relative overflow-hidden">
                                <div class="bg-overlay bg-dark bg-gradient"></div>
                            </div>
                        </div>
                    </div>
                    <!-- end card -->
                </div>
            </div>
            <!-- end row -->
        </div>

        <div class="row">
            <div class="col-xl-3">
                <div class="card mt-n5">
                    <div class="card-body text-center">
                        <div v-if="false" class="avatar-xl mx-auto mb-4">
                            <img
                                src="@/assets/images/users/avatar-1.jpg"
                                alt=""
                                class="rounded-circle img-thumbnail"
                            />
                        </div>
                        <h5 class="mb-1">{{ user.realname }}</h5>
                        <p class="text-muted">{{ user.company_name }}</p>

                        <div>
                            <i
                                class="mdi mdi-wallet text-muted font-size-16 align-middle me-1"
                            ></i>
                            <span class="align-middle"
                            >{{ $t('balance.balance') }} :
                            <b class="fw-semibold font-size-16">{{ formatter.format(balance) }}</b></span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end border-top p-2">
                        <div class="flex-grow-1" v-if="false">
                            <div class="p-2 ps-3">$40/-hr</div>
                        </div>
                        <div class="flex-shrink-0">
                            <b-button type="button" class="btn btn-light" :to="messageLink">
                                <i class="uil uil-envelope-alt me-1"></i> {{ $t('balance.messages') }}
                            </b-button>
                        </div>
                    </div>
                </div>
                <!-- end card -->

                <div class="card">
                    <div class="card-body p-0">
                        <ul class="list-unstyled mb-0 px-4 py-2">
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="font-size-20 text-primary flex-shrink-0 me-3">
                                        <i class="uil uil-map-marker"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="mb-0 font-size-14">{{ $t('balance.billing_data') }}</h5>
                                        <p class="text-muted mb-1 font-size-13">{{ user.company_name }}, {{ user.address }}</p>
                                    </div>
                                </div>
                            </li>
                            <!-- end li -->
                            <!-- end li -->
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="font-size-20 text-primary flex-shrink-0 me-3">
                                        <i class="uil uil-envelope-alt"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="mb-0 font-size-14">{{ $t('login.email') }}</h5>
                                        <p class="text-muted mb-1 font-size-13">{{ user.email }}</p>
                                    </div>
                                </div>
                            </li>
                            <!-- end li -->
                            <li class="pt-3">
                                <div class="d-flex align-items-center">
                                    <div class="font-size-20 text-primary flex-shrink-0 me-3">
                                        <i class="uil uil-phone"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="mb-0 font-size-14">{{ $t('balance.phone') }}</h5>
                                        <p class="text-muted mb-1 font-size-13">{{ user.phone }}</p>
                                    </div>
                                </div>
                            </li>
                            <!-- end li -->
                        </ul>
                        <div class="d-flex justify-content-end border-top p-2">
                            <div class="flex-shrink-0">
                                <b-button class="btn btn-light" :to="$t('routes.profile_modify')">
                                    <i class="uil uil-edit me-1"></i> {{ $t('balance.edit') }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->

            </div>
            <!-- end col -->

            <div class="col-xl-9">
                <div class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <slot />
                        </div>
                    </div>
                    <!-- end row -->
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
