<template>
    <b-form @submit.prevent>
        <div class="card">
            <div v-if="!modal" class="card-header">
                <h4 class="card-title">
                    <b-link v-if="!modal" @click="$router.go(-1)">
                        <i class="fas fa-long-arrow-alt-left me-2"></i>
                    </b-link>
                    {{ $t(model && model.m_id ? 'contact.modify_address' : 'contact.add_address', { id: model && model.m_id }) }}
                </h4>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                            id="name"
                            class="row"
                            :label="$t('contact.name')"
                            label-for="name-input"
                            label-cols="12"
                            content-cols="12"
                        >
                            <b-form-input id="name-input" v-model="model.name" :placeholder="$t('contact.name')"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group
                            id="phone_number"
                            class="row"
                            :label="$t('contact.phone')"
                            label-for="phone-number-input"
                            label-cols="12"
                            content-cols="12"
                        >
                            <vue-tel-input id="phone-number-input" v-model="model.phone_number" :defaultCountry="defaultLocale" mode="international" validCharactersOnly :inputOptions="{placeholder: $t('contact.placeholders.phone')}"></vue-tel-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div v-if="!modal" class="card-footer text-end">
                <b-button variant="primary" @click="save">
                    <i class="fas fa-save me-2"></i>{{ $t('contact.save') }}</b-button>
            </div>
        </div>
    </b-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "AddressForm",

    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            parent: 'contact/item',
            list: 'contact/address/list',
            address: 'contact/address/item'
        }),

        defaultLocale: function () {
            return navigator.language
        }
    },

    data: function () {
        return {
            model: {phone_number: ''}
        }
    },

    methods: {
        save: function () {
            const action = this.model && this.model.m_id ? 'update': 'create';

            return this.$store.dispatch(`address/${action}`, Object.assign({id_m_contact_list: this.parent.m_id}, this.model));
        },

        setModel: function (model = {}) {
            this.model = Object.assign({phone_number: ''}, model)
        },
    },

    watch: {
        address: {
            immediate: true,
            deep: true,
            handler: function (address) {
                this.setModel(address)
            },
        }
    },

    created() {
        if (!this.modal) {
            const action = this.list && this.list.length ? 'choose' : 'fetchOne';

            this.$store.dispatch(`contact/address/${action}`, this.$route.params.id);
        }
    },

    destroyed() {
        this.$store.dispatch('address/emptyItem');
    }
}
</script>

<style scoped>

</style>
