<template>
    <Layout>
        <address-form></address-form>
    </Layout>
</template>

<script>
import Layout from "../../layouts/table";
import AddressForm from "@/views/pages/contact/address.form";

export default {
    name: "AddressEdit",

    components: { Layout, AddressForm }
}
</script>

<style scoped>

</style>
